.location-viewer__table {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden
}

.location-viewer__header {
    font-weight: bold;
}

.location-viewer__new-location {
    margin: 6px 2px 2px 2px;
}
