@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

@media(min-width: 768px) {
    body {
        display: flex;
        flex-direction: column;
    }

    #root {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: 100vh;
    }
}


.navbar {
    margin-bottom: 0px;
}
.wodinBody {
    flex: 1 1 auto;
    height: 300px;
    overflow: auto;
    background-image: -webkit-linear-gradient(45deg, lightgrey, transparent);
    background-image: linear-gradient(45deg, lightgrey, transparent);
}

.wodinFooter {
    width: 99.5%;
    padding-bottom: 1rem;
    padding-right: 0rem;
    padding-top: 1rem;
    margin-top: .5rem 1rem;
    margin-bottom: .5rem 1rem;
    margin-right: 0rem;
    color: white;
    background-color:#101010;
}

.appLayout {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.location-viewer__table {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden
}

.location-viewer__header {
    font-weight: bold;
}

.location-viewer__new-location {
    margin: 6px 2px 2px 2px;
}

.observation-editor__container {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
}

.observation-editor__column {
    width: 58vw;
}

.observation-editor__item {
    display: inline-block;
    width: 24%;
    height: 85px;
    padding: 3px;
    vertical-align: top;
}

.observation-editor__optgroup-label {
    font-size: 80%;
    font-weight: 400;
    margin-bottom: 0;
}

.observation-editor__optgroup {
    width: 100%;
    height: 38px;
}

.observation-editor__blank{
    display: flex;
}
.report-editor__button {
    margin: 2px;
}

.Warning_Time{
    color:  red;
    font-weight: bold;
}

.Warning_Report{
    color:  red;
    font-weight: bold;
}

.Warning_Checks{
    color:  red;
    font-weight: bold;
}
.observation-viewer__table {
    overflow-y: auto;
    overflow-x: hidden
}

.observation-viewer__col {
    padding-left: 3px;
    padding-right: 3px;
}

.observation-report-viewer__row {
    margin-top: 2px;
    margin-bottom: 2px;
}

.observation-viewer__header {
    font-weight: bold;
}

.observation-viewer__new-observation {
    margin: 6px 2px 2px 2px;
    float: left;
}

.locationSelection {
    margin-top: 0.5em;
}

.observation-report-viewer__edit-report {
    margin-left: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 0px;
}
.loginForm {
    max-width: 600px;
}


