@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

@media(min-width: 768px) {
    body {
        display: flex;
        flex-direction: column;
    }

    #root {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: 100vh;
    }
}

