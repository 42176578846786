.observation-editor__container {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
}

.observation-editor__column {
    width: 58vw;
}

.observation-editor__item {
    display: inline-block;
    width: 24%;
    height: 85px;
    padding: 3px;
    vertical-align: top;
}

.observation-editor__optgroup-label {
    font-size: 80%;
    font-weight: 400;
    margin-bottom: 0;
}

.observation-editor__optgroup {
    width: 100%;
    height: 38px;
}

.observation-editor__blank{
    display: flex;
}