.wodinFooter {
    width: 99.5%;
    padding-bottom: 1rem;
    padding-right: 0rem;
    padding-top: 1rem;
    margin-top: .5rem 1rem;
    margin-bottom: .5rem 1rem;
    margin-right: 0rem;
    color: white;
    background-color:#101010;
}
