.report-editor__button {
    margin: 2px;
}

.Warning_Time{
    color:  red;
    font-weight: bold;
}

.Warning_Report{
    color:  red;
    font-weight: bold;
}

.Warning_Checks{
    color:  red;
    font-weight: bold;
}