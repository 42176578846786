.observation-viewer__table {
    overflow-y: auto;
    overflow-x: hidden
}

.observation-viewer__col {
    padding-left: 3px;
    padding-right: 3px;
}

.observation-report-viewer__row {
    margin-top: 2px;
    margin-bottom: 2px;
}

.observation-viewer__header {
    font-weight: bold;
}

.observation-viewer__new-observation {
    margin: 6px 2px 2px 2px;
    float: left;
}

.locationSelection {
    margin-top: 0.5em;
}

.observation-report-viewer__edit-report {
    margin-left: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 0px;
}